var carousel = new Swiper('.carousel', {

  slidesPerView: 4,
  spaceBetween: 30,
  slideToClickedSlide: true,
  loop: true,

  navigation: {
    nextEl: '.carousel__next',
    prevEl: '.carousel__prev',
  },
  breakpoints: {

    768: {
      slidesPerView: 4,
      spaceBetween: 30
    },
    576: {
    slidesPerView: 3,
      spaceBetween: 20
  },
  320: {
    slidesPerView: 1,
      spaceBetween: 20
  },
}
});